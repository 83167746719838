import { onCleanup, onMount } from "solid-js";

export const useOutsideClick = (
  ref: HTMLDivElement | null,
  callback: () => void
) => {
  const handleOutsideClick = (event: MouseEvent) => {
    if (!ref || ref.contains(event.target as Node)) {
      return;
    }

    const targetElement = event.target as HTMLElement;
    if (targetElement.closest("a, button")) {
      return;
    }

    callback();
  };

  onMount(() => {
    document.addEventListener("mousedown", handleOutsideClick);
  });

  onCleanup(() => {
    document.removeEventListener("mousedown", handleOutsideClick);
  });
};
