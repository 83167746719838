import { Show } from "solid-js";
import { giftCardPurple } from "~/assets/assets";
import { PhosphorIcon } from "~/widgets/icons";
import { RandomEmojiFaces } from "../brand_l2/trust_marker_happy_users";

function splitAfterOn(sentence: string) {
  const parts = sentence.split(/(on\s+)/);
  return [parts[0] + parts[1], parts.slice(2).join("")];
}

export const GroupTextHeaderRecipient = (props: { text: string }) => {
  return (
    <Show when={props.text}>
      <div class="py-18 flex text-center">
        <h1 class="mx-auto text-h3 md:text-h1">
          {splitAfterOn(props?.text)[0]}
          <br />
          <div class="mt-2 inline-flex items-baseline gap-1">
            <img
              class="h-8 self-center md:h-11"
              src={giftCardPurple}
              alt="gift-card"
            />
            <span class="inline-block bg-gradient-to-br from-graidentPurple to-graidentBlue bg-clip-text px-1 text-h3 text-transparent md:text-h1">
              Gift Cards
            </span>
            {splitAfterOn(props?.text)[1]}
          </div>
        </h1>
      </div>
    </Show>
  );
};

export const GroupTextHeaderOccasion = (props: { text: string }) => {
  return (
    <Show when={props.text}>
      <div class="flex py-8 text-center">
        <h1 class="mx-auto text-h3 md:text-h1">
          {splitAfterOn(props?.text)[0]}
          <br />
          <div class="mt-2 inline-flex items-baseline gap-1">
            {splitAfterOn(props?.text)[1]}
            <img
              class="h-8 self-center md:h-11"
              src={giftCardPurple}
              alt="gift-card"
            />{" "}
            <span class="inline-block bg-gradient-to-br from-graidentPurple to-graidentBlue bg-clip-text px-1 text-h3 text-transparent md:text-h1">
              Gift Cards
            </span>
          </div>
        </h1>
      </div>
    </Show>
  );
};

export const TrustMakerStrip = () => {
  return (
    <div class="mx-4 my-5 overflow-x-hidden rounded-lg bg-gray-100 px-4 py-3 sm:mx-0 lg:mx-auto lg:flex lg:max-w-[1137px] lg:justify-center">
      <div class="flex w-max animate-[move_15s_linear_infinite] items-stretch justify-around gap-6 lg:animate-none">
        <div class="flex items-center gap-1 whitespace-nowrap">
          <PhosphorIcon
            fontSize={24}
            name={"lightning"}
            size="fill"
            class="text-green-600"
          />
          <p class="text-normal font-semibold text-successDark">
            Instant gift card e-delivery here & on WhatsApp
          </p>
        </div>

        <div class="flex items-center gap-1 whitespace-nowrap">
          <RandomEmojiFaces
            styles={{ emoji: "h-6 w-6", container: "w-16 h-6 contents" }}
          />
          <p class="text-normal font-semibold text-teal-800">5L+ happy users</p>
        </div>

        <div class="flex items-center gap-1 whitespace-nowrap">
          <PhosphorIcon
            fontSize={24}
            name={"piggy-bank"}
            size="fill"
            class="text-blue-900"
          />
          <p class="text-normal font-semibold text-blue-900">
            Saved ₹10Cr till date
          </p>
        </div>

        <div class="flex items-center gap-1 whitespace-nowrap">
          <PhosphorIcon
            fontSize={24}
            name={"shield-check"}
            size="fill"
            class="text-purple-900"
          />
          <p class="text-normal font-semibold text-purple-900">Safe & secure</p>
        </div>
      </div>
    </div>
  );
};
